export const environment = {
  production: true,

  //Local
//  SERVICE_NAME: 'http://localhost:5001/v1/',
//  LOGIN_SERVICE: 'http://localhost:5001/v1/auth/'

  // TEST
  // SERVICE_NAME: 'https://ies-check.eastus2.cloudapp.azure.com/v1/',
  // LOGIN_SERVICE: 'https://ies-check.eastus2.cloudapp.azure.com/v1/auth/'

  // PROD
   SERVICE_NAME: 'https://4sightgps.com/v1/',
   LOGIN_SERVICE: 'https://4sightgps.com/v1/auth/'

};
