import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/app/environments/environment.prod';
const LOGIN_SERVICE = environment.LOGIN_SERVICE;

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    user: any;
    sessionValidMinutes = 0;


    tokenFromUI: string = "0123456789123456";

    constructor(public router: Router, public iziToast: ToastrService) {
        this.user = sessionStorage?.['user'];

    }

    encryptUsingAES256(value?: string): string {
        let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        let encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(value), _key, {
            keySize: 16,
            iv: _iv,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    }

    decryptUsingAES256(value?: string): string {
            let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
            let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

            let decrypted = CryptoJS.AES.decrypt(
                value, _key, {
                    keySize: 16,
                    iv: _iv,
                    mode: CryptoJS.mode.ECB,
                    padding: CryptoJS.pad.Pkcs7
                }).toString(CryptoJS.enc.Utf8);
            return JSON.parse(decrypted);
            // return decrypted;

    }

    joinPrincipalName(userObj): string {
        const emailId = new String(userObj.emailId);
        const notation = new String('@');
        const tenantId = new String(userObj.tenantId);

        let userPrincipalName = notation.concat(tenantId.toString());
        userPrincipalName = emailId.concat(userPrincipalName.toString());
        return userPrincipalName;
    }

    joinFirstandLastName(userObj): string {
        const firstName = new String(userObj.firstName);
        const middleName = new String(' ');
        const lastName = new String(userObj.lastName);
        let displayName = middleName.concat(lastName.toString());
        displayName = firstName.concat(displayName.toString());
        return displayName;
    }

    splitUserPrincipalName(userPrincipalName): string {
        userPrincipalName = userPrincipalName;
        userPrincipalName = userPrincipalName.split("@");
        return userPrincipalName;
    }


    // mockDataBirthDate(register: Register): Date {
    //     const parentAddress = this.decryptUsingAES256(register?.parentAddress);
    //     return register.parentAddress = parentAddress.replace(/[A-Za-z0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\?~]/g, "*"); 
    // }

    showSuccessMessage(data) {
        this.iziToast.success(data);
    }

    showErrorMessage(data) {
        this.iziToast.error(data);
    }

    convertStringToboolean(value?: any): boolean {
        if (value === '0') {
            return value === '0' ? false : true;

        } else {
            return value === '1' ? true : false;

        }
        // return true;

    }

    base64ArrayBuffer(arrayBuffer) {
        var base64 = ''
        var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

        var bytes = new Uint8Array(arrayBuffer)
        var byteLength = bytes.byteLength
        var byteRemainder = byteLength % 3
        var mainLength = byteLength - byteRemainder

        var a, b, c, d
        var chunk

        // Main loop deals with bytes in chunks of 3
        for (var i = 0; i < mainLength; i = i + 3) {
            // Combine the three bytes into a single integer
            chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]

            // Use bitmasks to extract 6-bit segments from the triplet
            a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
            b = (chunk & 258048) >> 12 // 258048   = (2^6 - 1) << 12
            c = (chunk & 4032) >> 6 // 4032     = (2^6 - 1) << 6
            d = chunk & 63               // 63       = 2^6 - 1

            // Convert the raw binary segments to the appropriate ASCII encoding
            base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
        }

        // Deal with the remaining bytes and padding
        if (byteRemainder == 1) {
            chunk = bytes[mainLength]

            a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2

            // Set the 4 least significant bits to zero
            b = (chunk & 3) << 4 // 3   = 2^2 - 1

            base64 += encodings[a] + encodings[b] + '=='
        } else if (byteRemainder == 2) {
            chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]

            a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
            b = (chunk & 1008) >> 4 // 1008  = (2^6 - 1) << 4

            // Set the 2 least significant bits to zero
            c = (chunk & 15) << 2 // 15    = 2^4 - 1

            base64 += encodings[a] + encodings[b] + encodings[c] + '='
        }

        return base64
    }

    logout(data?) {
        sessionStorage.clear();
        // this.router.navigate(['/access/']);
        this.router.navigate([]).then(result => {  window.open( `${LOGIN_SERVICE}signout`, '_self'); });

        // this.authService.logoutRedirect({
        //     postLogoutRedirectUri: 'http://localhost:4200/access/',
        //   });

        //   this.authService.logoutRedirect();


    }


}
