import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user/user';
import { SigninService } from 'src/app/services/api/signin.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/app/environments/environment.prod';
const LOGIN_SERVICE = environment.LOGIN_SERVICE;

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  animations: [
    trigger('error', [
      transition(':enter', [
        style({ transform: 'translateY(-10px)' }),
        animate('100ms ease-out', style({ transform: 'none' }))
      ])
    ])
  ]
})
export class SigninComponent extends OnDestroyMixin implements OnInit {

  LoginForm: FormGroup;
  signinObj: User = {};
  filterSubscription: Subscription;
  loading: boolean;

  constructor(private router: Router, private formBuilder: FormBuilder,
    public signinService: SigninService, private common: CommonService,
    private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {

  }


  azureLogin(): void {
    this.router.navigate([]).then(result => {  window.open( `${LOGIN_SERVICE}signin`, '_self'); });

  }

}
