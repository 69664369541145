import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { User } from 'src/app/models/user/user';
import { Data } from 'src/app/models/user/data';
import { environment } from 'src/app/environments/environment.prod';
import { AllUsers } from 'src/app/models/user/blueprint';
import { map } from 'rxjs/operators';


const SERVICE_NAME = environment.SERVICE_NAME;
const LOGIN_SERVICE = environment.LOGIN_SERVICE;

@Injectable({
  providedIn: 'root'
})

export class SigninService {

  constructor(private http: HttpClient) {
  }


  checkUser(login?: User): Observable<Data> {
    return this.http.post<Data>(LOGIN_SERVICE + 'login', login);
  }

  rolesList(): Observable<Data> {
    return this.http.post<Data>(LOGIN_SERVICE + 'listRoles', '');
  }

  directoryRolesList(): Observable<Data> {
    return this.http.post<Data>(LOGIN_SERVICE + 'listAllDirectoryRoles', '');
  }

  createUser(user?: User): Observable<Data> {
    return this.http.post<Data>(LOGIN_SERVICE + 'createUser', user);
  }

  updateUser(user?: User): Observable<Data> {
    return this.http.post<Data>(LOGIN_SERVICE + 'updateUser', user);
  }

  assignUserPermission(user?: User): Observable<Data> {
    return this.http.post<Data>(LOGIN_SERVICE + 'setPermission', user);
  }

  assignApproleToUser(user?: User): Observable<Data> {
    return this.http.post<Data>(LOGIN_SERVICE + 'assignApproleToUser', user);
  }

  removeAppRoleToUser(user?: User): Observable<Data> {
    return this.http.post<Data>(LOGIN_SERVICE + 'removeAppRoleToUser', user);
  }

  listAllUsersIntheAppId(user?: User): Observable<Data> {
    return this.http.post<Data>(LOGIN_SERVICE + 'listAllUsersIntheAppId', '');
  }

  listAllUsers(user?: User): Observable<Data> {
    return this.http.post<Data>(LOGIN_SERVICE + 'listAllUsers', '');
  }

  getUserById(princiaplId?: any): Observable<AllUsers> {
    return this.http.post<AllUsers>(LOGIN_SERVICE + 'getUserById', { userId: princiaplId });
  }

  getUserByPrincipalId(princiaplId?: any): Observable<AllUsers> {
    return this.http.post<AllUsers>(LOGIN_SERVICE + 'getUserByPrincipalId', { userId: princiaplId });
  }

  getUserProfilePic(princiaplId?: any): Observable<AllUsers> {
    return this.http.post<AllUsers>(LOGIN_SERVICE + 'getUserProfilePic', { userId: princiaplId });
  }

  getProfilePic(): Observable<AllUsers> {
    return this.http.post<AllUsers>(LOGIN_SERVICE + 'getProfilePic', '');
  }

  changePassword(userEmailId: User): Observable<Data> {
    return this.http.post<Data>(LOGIN_SERVICE + 'changePassword', userEmailId);
  }

  updateProfilePic(file): Observable<Data> {
    return this.http.post<Data>(LOGIN_SERVICE + 'updateProfilePic', file);
  }

  deleteUser(princiaplId?: any): Observable<Data> {
    return this.http.post<Data>(LOGIN_SERVICE + 'deleteUserById', { princiaplId: princiaplId });
  }


  usersList(): Observable<Data> {
    return this.http.post<Data>(SERVICE_NAME + 'listUsers', '');
  }

  // getUserByIds(userId?: any): Observable<Data> {
  //   return this.http.post<Data>(LOGIN_SERVICE + 'getUserById', userId);
  // }


  // createUser(user?: User): Observable<Data> {
  //   return this.http.post<Data>(SERVICE_NAME + 'createUser', user);
  // }

  // getUserById(approvedId?: any): Observable<User> {
  //   return this.http.post<User>(SERVICE_NAME + 'getUserById', { id: approvedId });
  // }



  postEmailId(userEmailId: User): Observable<Data> {
    return this.http.post<Data>(SERVICE_NAME + 'validateEmail', userEmailId);
  }

}