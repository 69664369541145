<!-- <div class="container-fluid" style="padding: 0 10px;height:100vh;">
  <div class="row">
    <div class="col-md-6 col-sm-12" style="position: relative;height: 100vh;padding: 0px">

      <img src="../../../assets/images/login-bg.png" style="position:absolute;bottom: 0px;width: 100%;" />
    </div>
    <div class="col-md-6 col-sm-12" style="">
      <div class="row" style="margin-top:25vh">

        <div class="col-md-4">
        </div>
        <div class="col-md-4">
        </div>
        <div class="col-md-4">
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <p style="font-size:12px;">I'm an Advocate <span style="display: inline-block;margin-left:15px"><i
                class="fa fa-arrow-right"></i></span></p>

          <div class="h-180px p-5 cursor-pointer text-center"
            style="border:1px solid #F15958;background-color: #FF72711A;border-radius:20px">
            <img src="../../../assets/images/data-dashboard.png" />

            <h4 class="mt-4">Data <span style="font-weight:normal;display:block;">Dashboard</span></h4>
          </div>

        </div>
        <div class="col-md-4">
          <p style="font-size:12px;">I'm a Parent / Student <span style="display: inline-block;margin-left:15px"><i
                class="fa fa-arrow-right"></i></span></p>

          <div class="h-180px p-5 cursor-pointer text-center"
            style="border:1px solid #FFCE00; background-color: #FFCE001A;border-radius:20px">

            <img src="../../../assets/images/view-roadmap.png" />

            <h4 class="mt-4">View <span style="font-weight:normal;display:block;">Roadmap</span></h4>
          </div>
        </div>
        <div class="col-md-4" style="">
          <p style="font-size:12px;">I belong to the Ecosystem <span style="display: inline-block;margin-left:5px"><i
                class="fa fa-arrow-right"></i></span></p>

            <div class="h-180px p-5 cursor-pointer text-center"
              onclick="location.href='http://localhost:5001/v1/brcc/signin'"
              style="border-radius:20px;border:1px solid #00B1CC;background-color: #00B1CC1A">
              <img src="../../../assets/images/ecosystem.png" />

              <h4 class="mt-4">Ecosystem <span style="font-weight:normal;display:block;">Login</span></h4>
            </div>

        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="flex">
  <div class="flex-col flex-1">
    <img src="../../../assets/images/login-bg.png" style="width: 50%;" class="absolute bottom-0	" />
  </div>

  <div class="flex-col relative top-60">
    <div class="flex flex-row gap-x-6">

      <div class="flex-col">
        <div class="pb-10 fs-12 mat-body-2 m-0 fw-400">I'm an Advocate <span class="ml-6 fw-default"><i class="fa fa-arrow-right"></i></span></div>
        <div class="h-200 p-30 cursor-pointer text-center"
          style="border:1px solid #F15958;background-color: #FF72711A;border-radius:20px">
          <img src="../../../assets/images/data-dashboard.png" />
            <div class="fw-600 pt-6 mat-title mat-body-1 m-0" fxLayoutAlign="center center">Data</div>
            <div class="mat-body-2 m-0 fw-400" fxLayoutAlign="center center">Dashboard</div>
        </div>
      </div>


      <div class="flex-col">
        <div class="pb-10 fs-12 mat-body-2 m-0 fw-400">I'm a Parent / Student <span class="ml-6 fw-default"><i class="fa fa-arrow-right"></i></span></div>
        <div class="h-200 p-30 cursor-pointer text-center"
          style="border:1px solid #FFCE00; background-color: #FFCE001A;border-radius:20px">
          <img src="../../../assets/images/view-roadmap.png" />
            <div class="fw-600 pt-6 mat-title mat-body-1 m-0" fxLayoutAlign="center center">View</div>
            <div class="mat-body-2 m-0 fw-400"  fxLayoutAlign="center center">Roadmap</div>
        </div>
      </div>




      <div class="flex-col mr-20">
        <div class="pb-10 fs-12 mat-body-2 m-0 fw-400">I belong to the Ecosystem <span class="ml-6 fw-default"><i class="fa fa-arrow-right"></i></span></div>
        <div class="h-200 p-30 cursor-pointer text-center" (click)="azureLogin()"
          style="border-radius:20px;border:1px solid #00B1CC;background-color: #00B1CC1A">
          <img src="../../../assets/images/ecosystem.png" />
            <div class="fw-600 pt-6 mat-body-1 m-0" fxLayoutAlign="center center">Ecosystem</div>
            <div class="mat-body-2 m-0 fw-400" fxLayoutAlign="center center">Login</div>
        </div>
      </div>



    </div>
  </div>
</div>